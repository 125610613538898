@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
* {
  padding: 0px;
  margin: 0px;
}
body {
  background: #fff;
  // background-image: url(../images/hero-bg-1-3.png);
  // background-attachment: fixed;
  // background-size: cover;
  // background-blend-mode: screen;
  font-family: "Poppins", sans-serif;
  color: #000;
}
input,
button {
  font-family: "Poppins", sans-serif;
}
.radio {
  margin: 0;

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      &:before {
        content: "";
        background: white;
        border-radius: 100%;
        border: 1px solid #000;
        display: inline-block;
        width: 1.26em;
        height: 1.26em;
        position: relative;
        // top: -0.2em;
        // margin-right: 0.5em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: #d7b25e;
          box-shadow: inset 0 0 0 4px #d7b25e;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #000;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #fff;
          border-color: darken(#fff, 25%);
          background: #fff;
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
.wrapper {
  padding: 2rem 1rem 1rem;
  margin: 0px;
  letter-spacing: 0.5px;
  // background: url(../images/footer-copyright-shape.png) no-repeat bottom;
  .container {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
      text-align: center;
      font-family: "Roboto Slab", serif;
      color: #ffe140;
      font-weight: 200;
      font-size: 52px;
    }

    .launchDetails {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      .logo {
        display: flex;
        justify-content: center;

        img {
          width: 100%;
          // max-width: 450px;
        }
      }
      .launchDate {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        gap: 0.5rem;
      }
      .launchTime {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
      }
      .myGardenLogo {
        text-align: center;
        line-height: 0px;
        padding: 1rem 0;
        img {
          max-width: 250px;
          width: 80%;
        }
      }
      .grandRelaunch {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        font-family: "Lora", serif;
        background-image: linear-gradient(
          to right,
          #f6e27a 0,
          #cb9b51 22%,
          #f6e27a 45%,
          #f1e339 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #ffe140 100%
        );
        color: transparent;
        -webkit-background-clip: text;
        position: relative;
        @media (max-width: 767px) {
          font-size: 30px;
        }
        .grandRelaunchShadow {
          position: absolute;
          text-shadow: -1px 2px 2px rgb(226 213 184);
          left: 0px;
          top: 0px;
          z-index: -1;
        }
      }
      .launchDestination {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        @media (max-width: 767px) {
          font-size: 22px;
        }
      }
    }

    .launchRegisterForm {
      display: flex;
      gap: 1rem;
      width: calc(100% - 2rem);
      max-width: 450px;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: center;
      background: #000000;
      color: #fff;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 0 4px 20px rgba(34, 34, 34, 0.15);
      font-size: 14px;

      input {
        &.inputBox {
          width: calc(100% - 2rem);
          outline: none;
          border: none;
          padding: 1rem;
          background: #ebebeb;
          border-radius: 4px;
        }
      }
      p {
        &.error {
          font-size: 11px;
          color: red;
          display: none;
        }
      }
      .title {
        width: calc(100% - 0rem);
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        border-bottom: 1px solid #d6aa4e;
        padding-bottom: 0.5rem;
        text-transform: uppercase;
      }
      .firstName,
      .lastName {
        width: calc(50% - 0.5rem);
        label {
          padding-bottom: 0.25rem;
          display: block;
        }
      }
      .email,
      .mobile,
      .socialCreds {
        width: calc(100% - 0.5rem);
        label {
          padding-bottom: 0.25rem;
          display: block;
        }
      }
      .mediaType {
        width: calc(100% - 0.5rem);
        display: flex;
        gap: 1rem;
        .mediaTypeSection {
          display: flex;
          gap: 0.5rem;
          label {
            font-weight: 400;
          }
        }
      }
      .action {
        width: calc(100% - 0.5rem);
        button {
          width: calc(100% - 0rem);
          outline: none;
          border: none;
          padding: 1rem;
          border-radius: 4px;
          background: #333;
          background-image: linear-gradient(
            to right,
            #f6e27a 0,
            #cb9b51 50%,
            #ffe140 100%
          );
          color: #fff;
          cursor: pointer;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 2px;
          position: relative;
          overflow: hidden;
          span {
            position: relative;
            z-index: 1;
          }
          &:before {
            content: "";
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000;
            background-image: linear-gradient(
              to right,
              #ffe140 0,
              #cb9b51 50%,
              #f6e27a 100%
            );
            border-radius: 4px;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          &:hover {
            &:before {
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
          }
          .Loading {
            position: absolute;
            width: calc(100% - 2.1rem);
            top: 0px;
            left: 0px;
            padding: 1rem;
            border-radius: 4px;
            background-image: linear-gradient(
              to right,
              #f6e27a 0,
              #cb9b51 50%,
              #ffe140 100%
            );
            z-index: 1;
          }
        }
      }
    }
    .launchRegisterFormSubmission {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: calc(100% - 4rem);
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: center;
      background: rgb(255 255 255);
      padding: 2rem;
      border-radius: 4px;
      box-shadow: 0 4px 20px rgba(34, 34, 34, 0.15);
      img {
        margin: 0 auto;
      }
      h2 {
        font-size: 36px;
        font-weight: 600;
        color: #192919;
        text-align: center;
      }
      h2:after {
        display: block;
        content: "";
        height: 5px;
        background: #77b43f;
        max-width: 50px;
        margin: 0 auto;
        margin-bottom: 1rem;
      }
      p {
        text-align: center;
        font-weight: 400;
        color: #333;
        font-size: 14px;
      }
      .calendar {
        display: flex;
        justify-content: center;
      }
    }

    .launchRSVP {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      color: #333;
      a {
        color: #333;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
}
.errorPopup {
  position: fixed;
  background: rgb(255 255 255 / 54%);
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(34, 34, 34, 0.15);
    padding: 1rem;
    color: red;
  }
}
